<template>
  <!-- Container element for the page -->
  <div class="page-influencer-discovery large-page">
    <!-- Show toggle buttons and search input -->
    <div class="d-flex justify-center justify-md-end flex-wrap">
      <div class="d-flex mt-3 mt-sm-0">
        <!-- Show the input for searching terms -->
        <div class="search-input">
          <relevance-search
            :platform="selectedPlatform"
            :disabled="shouldRelevanceSearchBeDisabled"
          />
        </div>
        <!-- The select options for different platforms -->
        <platform-selector
          :value="selectedPlatform"
          :show-snapchat="canUseSnapchat"
          :show-linkedin="canUseLinkedin"
          @input="handlePlatformChange"
          class="rounded-l-0"
        />
      </div>
    </div>

    <!-- Show the entire form here -->
    <div :key="reloadKey">
      <word-cloud :platform="selectedPlatform" />

      <!-- A grid -->
      <v-row>
        <!-- The left item -->
        <v-col class="d-flex align-end pb-8" cols="12" md="6" order="last" order-md="first">
          <!-- Show the reset filters button here -->
          <v-btn
            v-if="appliedFilters.length"
            text
            color="primary"
            @click="clearFilters"
          >
            <v-icon left>
              clear
            </v-icon>

            Reset Filters
          </v-btn>
        </v-col>

        <!-- The right item -->
        <v-col cols="12" md="6">
          <div v-if="canUseVerifiedFilter" class="pt-6 pb-3 d-flex justify-end">
            <filter-verified />
          </div>
          <div v-if="canUseAudienceDataFilter" class="pb-3 d-flex justify-end">
            <filter-has-audience-data />
          </div>
          <div v-if="canUserExcludePrivateFilter" class="pb-6 d-flex justify-end">
            <filter-exclude-private-accounts />
          </div>
        </v-col>
      </v-row>

      <!-- the filters card -->
      <v-card class="pa-4 box-shadow-md" outlined>
        <v-card-text>
          <!-- show all possible options for search/filter -->
          <filter-options :platform="selectedPlatform" />

          <v-divider class="mb-3 mt-4"></v-divider>

          <!-- view the chips for selected/applied filters -->
          <applied-filters :filters="appliedFilters" @remove="handleRemove" />
        </v-card-text>

        <v-card-actions>
          <!-- Show saved searches button -->
          <v-btn
            text
            depressed
            color="primary"
            @click="shouldShowSavedSearchesDialog = true"
          >
            <v-icon left>
              bookmarks
            </v-icon>

            Saved Searches
          </v-btn>

          <!-- Space in between -->
          <v-spacer></v-spacer>

          <!-- Show search button -->
          <v-btn
            depressed
            color="buttonPrimary"
            :loading="isRequestingSearch"
            :disabled="isRequestingSearch"
            @click="doSearch"
          >
            <v-icon left>
              search
            </v-icon>

            Search
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- If we have anything to show in the table, show it -->
    <template v-if="shouldShowResults">
      <!-- Use the children view for table -->
      <results-table
        :key="resultKey"
        :platform="selectedPlatform"
        :results="results"
        :is-requesting-export="isRequestingExport"
        :last-search-request="lastSearchRequest"
        @export="handleExport"
      />

      <!-- If we should show the load more button -->
      <div
        v-if="shouldShowLoadMoreCTA && results && results.accounts && results.accounts.length < results.total"
        class="d-flex justify-center py-4"
      >
        <v-btn
          depressed
          color="primary"
          class="shadow--primary"
          @click="shouldShowPaginationDialog = true"
        >
          Load more
        </v-btn>
      </div>

      <!-- If there are less some items to be unlocked but no next page  -->
      <div
        v-if="results.accounts.length >= results.total && unlockCost > 0"
        class="d-flex justify-center py-4"
      >
        <v-btn
          depressed
          color="primary"
          class="shadow--primary"
          @click="shouldShowPaginationDialog = true"
        >
          Unlock Accounts
        </v-btn>
      </div>

      <!-- DOM element to be used to track scroll -->
      <div id="intersect-detector" ref="intersectDetector" v-intersect="handleIntersect"></div>
    </template>

    <!-- Dialog for confirmation about pagination -->
    <v-dialog v-model="shouldShowPaginationDialog" width="500">
      <v-card>
        <v-card-title> Confirmation </v-card-title>

        <v-card-text>
          To See this report
          <br />
          Service Cost
          <b> {{ unlockCost }} credits </b>
          <br />
          Your Balance
          <b> {{ availableCredits }} credits </b>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="shouldShowPaginationDialog = false"> Cancel </v-btn>

          <v-btn depressed color="primary" @click="paginateSearch"> Proceed </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the popup about the usage -->
    <v-dialog v-model="shouldShowInfoDialog" max-width="420">
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> lightbulb </v-icon>

          Info!
        </v-card-title>

        <!-- Show the image here -->
        <div class="d-flex justify-center py-16">
          <v-btn x-large depressed color="buttonPrimary">
            <v-icon left>search</v-icon>
            Search
          </v-btn>
        </div>

        <v-card-text>
          <p>Once you've applied filters, use the search button that looks like the one shown above to run your query!</p>
        </v-card-text>

        <v-card-actions>
          <!-- The checkmark -->
          <v-checkbox dense v-model="disableReminder" label="Don't remind again" />

          <v-spacer />

          <v-btn text color="primary" @click="handleInfoDialogClose"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the popup for saved searches -->
    <v-dialog
      v-model="shouldShowSavedSearchesDialog"
      max-width="800"
    >
      <v-card>
        <v-card-text class="pt-3">
          <saved-searches @close="shouldShowSavedSearchesDialog = false" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="shouldShowSavedSearchesDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"
import responseHelper from "@/helpers/responseHelper"
import filterConverter from "@/helpers/filterConverter"

// Import children components
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")
const FilterVerified = () => import(/* webpackChunkName: "filter-verified" */ "@/blocks/influencer-discovery/FilterVerified.vue")
const FilterHasAudienceData = () => import(/* webpackChunkName: "filter-has-audience-data" */ "@/blocks/influencer-discovery/FilterHasAudienceData.vue")
const FilterExcludePrivateAccounts = () => import(/* webpackChunkName: "filter-exclude-private-accounts" */ "@/blocks/influencer-discovery/FilterExcludePrivateAccounts.vue")

const RelevanceSearch = () => import(/* webpackChunkName: "relevance-search" */ "@/components/influencer-discovery/RelevanceSearch.vue")
const FilterOptions = () => import(/* webpackChunkName: "filter-options" */ "@/components/influencer-discovery/FilterOptions.vue")
const WordCloud = () => import(/* webpackChunkName: "word-cloud" */ "@/components/influencer-discovery/WordCloud.vue")
const AppliedFilters = () => import(/* webpackChunkName: "applied-filters" */ "@/components/common/AppliedFilters.vue")

const ResultsTable = () => import(/* webpackChunkName: "results-table-discovery" */ "@/components/influencer-discovery/ResultsTable.vue")
const SavedSearches = () => import(/* webpackChunkName: "saved-searches" */ "@/components/influencer-discovery/SavedSearches.vue")

// Define the messageEvent identifier
const subscriptionId = Symbol(window.location.pathname)

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerDiscovery",

  // Register children components
  components: {
    FilterVerified,
    FilterHasAudienceData,
    FilterExcludePrivateAccounts,
    PlatformSelector,
    FilterOptions,
    AppliedFilters,
    WordCloud,
    ResultsTable,
    SavedSearches,
    RelevanceSearch
  },

  // Define local data variables
  data: () => ({
    // When was the first request made
    requestCompletedAt: null,

    // Boolean operators
    shouldShowSavedSearchesDialog: false,
    shouldShowPaginationDialog: false,
    shouldShowResults: false,
    shouldShowLoadMoreCTA: false,
    shouldShowInfoDialog: false,

    // To track network requests
    isRequestingSearch: false,
    isRequestingExport: false,

    // Show global loaders
    globalSearchLoaderId: null,
    globalExportLoaderId: null,

    // To track where the request was made from
    searchTriggerId: null,
    exportTriggerId: null,

    // The timeout error alerts
    searchTimeoutHandler: null,
    exportTimeoutHandler: null,

    // To force refresh
    reloadKey: Symbol(),
    resultKey: Symbol(),

    lastSearchRequest: null,

    // Used to cancel networkRequests
    abortControllers: [],

    // The form value to track
    disableReminder: false,

    // To handle pagination
    form: {
      platform: "instagram",
      page: 1
    },

    // For debounce on search
    _timerId: null,
  }),

  // Define local readonly variables
  computed: {
    /**
     * Get the selected platform value
     *
     * @returns {String}
     */
    selectedPlatform() {
      return this.$store.getters["influencerDiscovery/selectedPlatform"]
    },

    /**
     * Get the API response to populate the table
     *
     * @returns {Array}
     */
    results() {
      return this.$store.getters["influencerDiscovery/results"](this.selectedPlatform)
    },

    /**
     * Get the total cost required to unlock the next page
     *
     * @returns {Number}
     */
    unlockCost() {
      return 0.04 * (this.results?.accounts?.filter((item) => item.is_locked) || []).length
    },

    /**
     * Get the authenticated user's profile data
     *
     * @returns {Object}
     */
    profileData() {
      return this.$store.getters["auth/profile"]
    },

    /**
     * Get the current logged in user's role attribute
     *
     * @returns {String}
     */
    profileRole() {
      return this.profileData ? this.profileData.role : "end-user"
    },

    /**
     * Whether or not the logged in user is an admin
     *
     * @returns {Boolean}
     */
    isAdmin() {
      return this.profileRole === this.constants.model.user.roles.admin
    },

    /**
     * Get the current user's credit balance amount
     *
     * @returns {Number}
     */
    availableCredits() {
      return this.profileData ? this.profileData.balance / 100 : 0
    },

    /**
     * Get the applied filters from vuex store
     *
     * @returns {Array}
     */
    appliedFilters() {
      return this.$store.getters[`influencerDiscovery/filters`].sort((a, b) => a.sort - b.sort)
    },

    /**
     * Whether or not the user can use snapchat
     *
     * @returns {Boolean}
     */
    canUseSnapchat() {
      return this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.platformDiscoverySnapchat)
    },

    /**
     * Whether or not the user can use linkedin
     *
     * @returns {Boolean}
     */
    canUseLinkedin() {
      return this.$store.getters["auth/isServiceAllowed"](constants.model.user.allowedServices.platformDiscoveryLinkedin)
    },

    /**
     * Whether or not the user can use audience data filter
     *
     * @returns {Boolean}
     */
    canUseVerifiedFilter() {
      // As long as the platforms are in these
      return ["instagram", "youtube", "tiktok"].includes(this.selectedPlatform)
    },

    /**
     * Whether or not the user can use audience data filter
     *
     * @returns {Boolean}
     */
    canUseAudienceDataFilter() {
      // As long as the platforms are in these
      return ["instagram", "youtube", "tiktok"].includes(this.selectedPlatform)
    },

    /**
     * Whether or not the user can use exclude private accounts filter
     *
     * @returns {Boolean}
     */
    canUserExcludePrivateFilter() {
      // As long as the platforms are in these
      return ["instagram", "youtube", "tiktok"].includes(this.selectedPlatform)
    },

    /**
     * Whether or not the relevance search input be disabled
     *
     * @returns {Boolean}
     */
    shouldRelevanceSearchBeDisabled() {
      // As long as the platforms are in these
      return ["instagram", "youtube", "tiktok"].includes(this.selectedPlatform) === false
    },

    /**
     * If this is a saved search
     *
     * @returns {Boolean}
     */
    isSavedSearch() {
      return this.$route.name === "SavedInfluencerDiscoverySearch"
    }
  },

  // Define local method functions
  methods: {
    /**
     * Show a loader for 2 seconds and then show the posts grid
     *
     * @param {Object} context - Whether to update timestamp value on request complete, and whether to reset the page data
     * @returns {void}
     */
    doSearch({ resetPage = true } = { resetPage: true }) {
      // If we're running a request already
      if (this.isRequestingSearch) {
        // Don't let it continue
        return
      }

      // Cancel pending call
      if (this._timerId) {
        clearTimeout(this._timerId)
      }

      // Delay new call 500ms
      this._timerId = setTimeout(async () => {
        // Cancel pending network requests
        for (const item of this.abortControllers) {
          item.controller.abort()
        }

        // If we should reset the page counter to 1
        if (resetPage) {
          this.form.page = 1
        }

        // Don't let multiple parallel requests to be initiated
        this.isRequestingSearch = true

        // Make network requests in vuex store
        const id = Symbol()
        const controller = new AbortController()

        // Push this controller to the list
        this.abortControllers.push({ id, controller })

        // Set the triggerId for tracking responses
        this.searchTriggerId = String(Date.now())

        // Clear previous timeout
        clearTimeout(this.searchTimeoutHandler)

        // Set a timeout function callback
        this.searchTimeoutHandler = setTimeout(() => {
          // Hide the loader
          this.isRequestingSearch = false

          // Show an error message
          this.$store.dispatch("toasts/add", { text: "Request timed out, please try again." })
        }, 12_000)

        // Dispatch the vuex action
        await this.$store.dispatch("influencerDiscovery/searchAccounts", {
          platform: this.selectedPlatform,
          page: this.form.page,
          triggerId: this.searchTriggerId,

          // Send the signal from abortController
          signal: controller.signal
        })

        // Remove the controller from cancellable array
        const index = this.abortControllers.findIndex((item) => item.id === id)
        this.abortControllers.splice(index, 1)
      }, 100)
    },

    /**
     * Whenever platform is switched
     * Reset the filters and the shouldShow state
     *
     * @param {String} value
     */
    handlePlatformChange(value) {
      this.shouldShowResults = false
      this.reloadKey = Symbol()
      this.form.page = 1

      this.$store.dispatch("influencerDiscovery/updateSelectedPlatform", value)
      this.$store.dispatch("influencerDiscovery/resetFilters")

      // also emit a global window event that the platform changed
      window.dispatchEvent(
        new CustomEvent("platformChange", {
          detail: {
            module: "influencerDiscovery",
            origin: "InfluencerDiscovery/handlePlatformChange",
            platform: this.selectedPlatform
          }
        })
      )
    },

    /**
     * Handle the scroll event for the table rows
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleIntersect(entries, observer, isIntersecting) {
      // If the timestamp doesn't exist
      if (!this.requestCompletedAt) return false

      // If the function is called under 2 seconds after it first loaded content, don't continue
      if (Date.now() - this.requestCompletedAt <= 1000) return false

      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If a request is being made, don't continue
      if (this.isRequestingSearch) return false

      // If there's no data right now, don't ask for next page
      if (this.results.accounts.length >= this.results.total) return false

      // If there's nothing to be charged
      if (this.unlockCost === 0) {
        this.paginateSearch()
      }
      // Otherwise show the dialog
      else {
        this.shouldShowPaginationDialog = true
      }
    },

    /**
     * Fetch the next page of the API data
     *
     * @returns {void}
     */
    paginateSearch() {
      // If we're already making a request, wait for it
      if (this.isRequestingSearch) return false

      // Hid the popup
      this.shouldShowPaginationDialog = false

      // Increment the current page number
      this.form.page++

      // Call the action to make network request
      console.log("calling doSearch from paginateSearch")
      this.doSearch({ resetPage: false })
    },

    /**
     * When the applied filter's remove icon is clicked
     *
     * @param {Object} data
     */
    handleRemove(data) {
      this.$store.dispatch(`influencerDiscovery/removeFilter`, data.id)
    },

    /**
     * Reset the applied filters when clicked
     *
     * @returns {void}
     */
    clearFilters() {
      this.shouldShowResults = false
      this.reloadKey = Symbol()
      this.form.page = 1

      this.$store.dispatch("influencerDiscovery/resetFilters")

      // If the current route is saved search
      if (this.isSavedSearch) {
        // Redirect to the main page
        this.$router.push({ name: "InfluencerDiscovery" })
      }
    },

    /**
     * Handle the search trigger when called through an event
     *
     * @returns {void}
     */
    handleSearchTrigger(e) {
      // If it's not the one we want, don't execute the code
      if (e.detail.module !== "influencerDiscovery") return

      // Make it search
      console.log("calling doSearch from handleSearchTrigger")
      this.doSearch()
    },

    /**
     * Handle the message event and update the UI
     *
     * @param {Object} data
     * @returns {void}
     */
    async handleMessageEvent(data) {
      // Depending on the event key value
      switch (data.key) {
        // Export: Started
        case "export-influencer-discovery-xlsx-started":
          // Clear the timeout function
          clearTimeout(this.exportTimeoutHandler)

          // If the triggerId doesn't match
          if (data.localData.triggerId !== this.exportTriggerId) {
            this.isRequestingExport = false
            return
          }

          // Otherwise, show a global loader
          this.globalExportLoaderId = Symbol()
          this.$store.dispatch("loaders/add", this.globalExportLoaderId)

          break

        // Export: Completed
        case "export-influencer-discovery-xlsx-completed":
          // If the triggerId doesn't match
          if (data.localData.triggerId !== this.exportTriggerId) {
            this.isRequestingExport = false
            return
          }

          // Anyways, hide the global loader
          this.$store.dispatch("loaders/remove", this.globalExportLoaderId)

          // Since the request is complete, enable the submit button
          this.isRequestingExport = false

          // Make sure table doesn't freeze
          this.resultKey = Symbol()

          // Refresh profile data with deducted balance
          window.dispatchEvent(
            new CustomEvent("fetchAuthUserProfile", {
              detail: {
                module: "influencerDiscovery",
                origin: "influencerDiscovery/exportAccounts"
              }
            })
          )

          break

        // Export: Failed
        case "export-influencer-discovery-xlsx-failed":
          // If the triggerId doesn't match
          if (data.localData.triggerId !== this.exportTriggerId) {
            this.isRequestingExport = false
            return
          }

          // Hide the global loader
          this.$store.dispatch("loaders/remove", this.globalExportLoaderId)

          // Since the request is complete, enable the submit button
          this.isRequestingExport = false
          // this.shouldShowResults = true // Keep this unaffected

          break

        // Search: Started
        case "query-influencer-discovery-started":
          // Clear the timeout function
          clearTimeout(this.searchTimeoutHandler)

          // If the triggerId doesn't match
          if (data.localData.triggerId !== this.searchTriggerId) {
            this.isRequestingSearch = false
            return
          }

          // Otherwise, show a global loader
          this.globalSearchLoaderId = Symbol()
          this.$store.dispatch("loaders/add", this.globalSearchLoaderId)

          break

        // Search: Completed
        case "query-influencer-discovery-completed":
          // If the triggerId doesn't match
          if (data.localData.triggerId !== this.searchTriggerId) {
            this.isRequestingSearch = false
            return
          }

          // Get the data from API
          const response = await responseHelper(data.localData.responseId)

          // If we do have some data
          if (response) {
            // Update the vuex store data
            this.$store.commit("influencerDiscovery/UPDATE_RESULTS", {
              platform: this.selectedPlatform,
              data: {
                currentPage: this.form.page,
                total: response.total,
                accounts: response.accounts.map((item) => ({
                  ...item,
                  platform: this.selectedPlatform
                })),
                savedSearch: response.savedSearch
              }
            })

            // Update the last search filters
            this.lastSearchRequest = this.$store.getters["influencerDiscovery/requestBody"]

            // If we should update the timestamp
            if (!this.requestCompletedAt) {
              // Use current timestamp value
              this.requestCompletedAt = Date.now()

              // Wait for a few seconds to show load more button if needed
              setTimeout(() => {
                // Get the intersect element
                const el = document.getElementById("intersect-detector")

                // If the page height is more than the table
                if (el && el.offsetTop < window.innerHeight) {
                  // Show the show more button option
                  this.shouldShowLoadMoreCTA = true
                }
                // Otherwise
                else {
                  // Hide it
                  this.shouldShowLoadMoreCTA = false
                }
              }, 500)
            }

            // Make sure table doesn't freeze
            this.resultKey = Symbol()

            // Refresh profile data with deducted balance
            window.dispatchEvent(
              new CustomEvent("fetchAuthUserProfile", {
                detail: {
                  module: "influencerDiscovery",
                  origin: "influencerDiscovery/searchAccounts"
                }
              })
            )
          }
          // Otherwise
          else {
            // Show an error message
            this.$store.dispatch("toasts/add", { text: "An error occurred" })
          }

          // Anyways, hide the global loader
          this.$store.dispatch("loaders/remove", this.globalSearchLoaderId)

          // Since the request is complete, enable the submit button
          this.isRequestingSearch = false
          this.shouldShowResults = true

          break

        // Search: Failed
        case "query-influencer-discovery-failed":
          // If the triggerId doesn't match
          if (data.localData.triggerId !== this.searchTriggerId) {
            this.isRequestingSearch = false
            return
          }

          // Hide the global loader
          this.$store.dispatch("loaders/remove", this.globalSearchLoaderId)

          // Since the request is complete, enable the submit button
          this.isRequestingSearch = false
          // this.shouldShowResults = true // Keep this unaffected

          // Show an error message
          this.$store.dispatch("toasts/add", { text: "An error occurred, please try again." })

          break
      }
    },

    /**
     * Make the network request to fetch the CSV from backend
     *
     * @param {String} calledFrom
     * @param {Number|Null} exportLimit
     * @returns {void}
     */
    async handleExport({ exportLimit, exportTitle, selectedRows, shouldExcludeContactDetails, shouldExcludePreviouslyExported }) {
      // If we were exporting one before, don't continue
      if (this.isRequestingExport) return

      // Otherwise, use the condition to show the loader on correct CTA button
      this.isRequestingExport = true

      // Create a triggerId
      this.exportTriggerId = String(Date.now())

      // Make the network request
      await this.$store.dispatch("influencerDiscovery/exportData", {
        exportLimit,
        exportTitle,
        selectedRows,
        platform: this.selectedPlatform,
        triggerId: this.exportTriggerId,
        // If the user is not an admin, this will always be false
        shouldIncludeContactDetails: this.isAdmin ? !shouldExcludeContactDetails : true,
        shouldExcludePreviouslyExported: shouldExcludePreviouslyExported
      })

      // Clear previous timeout handler
      clearTimeout(this.exportTimeoutHandler)

      // Set a timeout function callback
      this.exportTimeoutHandler = setTimeout(() => {
        // Hide the loader
        this.isRequestingExport = false

        // Show an error message
        this.$store.dispatch("toasts/add", { text: "Request timed out, please try again." })
      }, 12_000)
    },

    /**
     * Fetch the saved search data
     *
     * @returns {void}
     */
    async fetchSavedSearchData() {
      // Show the loader
      const loaderId = Symbol("InfluencerDiscovery/fetchSavedSearchData")
      this.$store.dispatch("loaders/add", loaderId)

      // Try making the network request
      try {
        // Fetch the response
        const response = await axios(`/api/influencer-discovery-searches/${this.$route.params.uuid}`)

        // Clear current filters
        this.$store.dispatch("influencerDiscovery/resetFilters", false)

        // Apply the filters
        for (const key of Object.keys(response.data.filters)) {
          // If the value is an array
          if (Array.isArray(response.data.filters[key])) {
            // Loop through each value
            for (const item of response.data.filters[key]) {
              this.$store.dispatch("influencerDiscovery/addFilter", await filterConverter(key, item))
            }
          }
          // Otherwise
          else {
            this.$store.dispatch("influencerDiscovery/addFilter", await filterConverter(key, response.data.filters[key]))
          }
        }

        // Apply the sort
        this.$store.dispatch("influencerDiscovery/updateSort", response.data.sort)
        // Apply audience source
        this.$store.dispatch("influencerDiscovery/updateAudienceSource", response.data.audience_source)

        // Once the filters have been applied
        console.log("filters added and now doing search", JSON.parse(JSON.stringify(this.appliedFilters)))

        // Set a timeout for 0.5 second
        setTimeout(() => {
          // Fetch for the first time
          console.log("calling from savedSearch")
          this.doSearch()
        }, 500)
      }
      catch (error) {
        // Log the error
        logger({ type: "InfluencerDiscovery/fetchSavedSearchData Network Error", error })

        // Show an error message
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try again." })
      }
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Hide the dialog box
     *
     * @returns {void}
     */
    handleInfoDialogClose() {
      this.shouldShowInfoDialog = false

      // If the checkbox was marked, stop showing it from now on
      if (this.disableReminder) {
        // Update the user meta
        this.$store.dispatch("auth/updateMeta", {
          key: constants.model.user.metas.popup.info.influencerDiscovery,
          value: "hidden"
        })
      }
    }
  },

  /**
   * As soon as the component has been rendered
   *
   * @returns {void}
   */
  async mounted() {
    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "influencer-discovery",
      type: "all",
      key: "all",
      validator: (event) => event.module === "influencer-discovery" && event.localData.clientId === getClientID(),
      callback: this.handleMessageEvent
    })

    // Listen for the inputs from relevance-search component
    window.addEventListener("triggerSearch", this.handleSearchTrigger)

    // Check if this is a saved search
    if (this.isSavedSearch) {
      console.log("This is a saved search")
      // Fetch the saved search data
      await this.fetchSavedSearchData()
    }
    // Otherwise
    else {
      // If we have echo registered
      if (window.HAS_ECHO_CONNECTION_BEEN_SET && window.HAS_ALL_ECHO_EVENTS_BEEN_SET) {
        // Set a timeout for 0.5 second
        setTimeout(() => {
          // Fetch for the first time
          this.doSearch()
        }, 500)
      }
      // Otherwise
      else {
        // Set a timeout for 1.5 second
        setTimeout(() => {
          // Fetch for the first time
          this.doSearch()
        }, 1500)
      }
    }

    // Check if we haven't clicked don't show again
    if (this.$store.getters["auth/getMetaValue"](constants.model.user.metas.popup.info.influencerDiscovery) === "should-show") {
      this.shouldShowInfoDialog = true
    }
  },

  /**
   * Right before the component is about to be destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the event listener
    window.removeEventListener("triggerSearch", this.handleSearchTrigger)

    // Remove the message event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus">
// to contain the entire box structure in large viewport
.page-influencer-discovery
  .search-input
    @media (min-width 960px)
      width 18em

    // to fix the label that's cropped at the bottom
    .v-select__slot label
      overflow visible !important
</style>
